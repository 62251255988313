import * as React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PageHero from "../components/page-hero";
import Fader from "../helper/Fader";

export const query = graphql`
  {
    page: allSanityPage(filter: { slug: { current: { eq: "expertise" } } }) {
      edges {
        node {
          id
          pageSEO {
            metaDescription
            metaImage {
              asset {
                url
              }
            }
            metaTitle
          }
          backgroundImage {
            asset {
              fixed(width: 1600, height: 900) {
                ...GatsbySanityImageFixed_withWebp_noBase64
              }
            }
          }
          title
          _rawIntro(resolveReferences: { maxDepth: 8 })
        }
      }
    }
    services: allSanityService(sort: { order: ASC, fields: title }) {
      edges {
        node {
          id
          title
          slug {
            current
          }
        }
      }
    }
  }
`;

class OurServices extends React.Component {
  render() {
    const page = this.props.data.page.edges[0].node;
    const services = this.props.data.services.edges;

    return (
      <Layout
        className="page-services-container"
        backgroundImage={page.backgroundImage?.asset?.fixed}
      >
        <SEO
          title={(page.pageSEO && page.pageSEO.metaTitle) || page.title}
          description={page.pageSEO && page.pageSEO.metaDescription}
          image={page.pageSEO && page.pageSEO.metaImage}
        />
        <PageHero title={page.title} intro={page._rawIntro} />
        <Fader>
          {" "}
          <section className="services-nav">
            <ul>
              {services.map((service, i) => {
                return (
                  <li className="service-link-item" key={i}>
                    <Link
                      className="service-link"
                      to={`/expertise/${service.node.slug.current}`}
                    >
                      <h2>{service.node.title}</h2>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </section>
        </Fader>
      </Layout>
    );
  }
}

export default OurServices;
